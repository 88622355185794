import types from './types';
import { periodTypes } from '../../components/Common/RecentDataDatePicker/constants';
import { LAST_PERIOD_MODE } from '../../components/Trends/constants';
import calculateDateRangeLimits from '../../components/Measurements/MeasurementsForm/DateSelectionSegment/calculateDateRangeLimits';
import { queryClient } from '../../index';
import {
  HEADER_ROW_DATE_FORMAT,
  INTERNAL_CHANNELS_SOURCE,
} from '../../components/MeteoDataExtraction/constants';

const defaultState = {
  form: {
    selectedMMS: null,
    stabilityAndHeatFluxMMS: null,
    precipitationRateMMS: null,
    dateBegin: null,
    dateEnd: null,
    periodNumber: 1,
    periodType: periodTypes.DAY,
    dateMode: LAST_PERIOD_MODE,
    extended: false,
  },
};

export default (state = defaultState, { type, ...action }) => {
  switch (type) {
    case types.SET_SELECTED_MMS: {
      const { selectedMMS } = action;
      const mmsWithStabilityAndHeatFlux = queryClient.getQueryData(
        'MMSWithStabilityClassAndHeatFlux'
      );
      const mmsWithPrecipitationRate = queryClient.getQueryData('MMSWithPrecipitationRate');
      const selectedMMSHasStabilityAndHeatFlux = mmsWithStabilityAndHeatFlux?.some(
        ms => ms.id === selectedMMS.id
      );
      const selectedMMSHasPrecipitationRate = mmsWithPrecipitationRate?.some(
        ms => ms.id === selectedMMS.id
      );

      return {
        ...state,
        form: {
          ...state.form,
          selectedMMS,
          stabilityAndHeatFluxMMS: selectedMMSHasStabilityAndHeatFlux
            ? INTERNAL_CHANNELS_SOURCE
            : null,
          precipitationRateMMS: selectedMMSHasPrecipitationRate ? INTERNAL_CHANNELS_SOURCE : null,
        },
      };
    }
    case types.UPDATE_FORM: {
      const { field, value } = action;
      return {
        ...state,
        form: {
          ...state.form,
          [field]: value,
        },
      };
    }
    case types.SEND_FORM: {
      const { form } = state;
      const { dateLowerLimit, dateUpperLimit } = calculateDateRangeLimits(form);
      const { selectedMMS, stabilityAndHeatFluxMMS, precipitationRateMMS } = form;

      return {
        ...state,
        currentRequestValues: {
          ...state.form,
          msId: selectedMMS.id,
          stabilityAndHeatFluxMSId: stabilityAndHeatFluxMMS.id,
          precipitationRateMSId: precipitationRateMMS.id,
          dateBegin: dateLowerLimit,
          dateEnd: dateUpperLimit,
          headerRow: `${selectedMMS.name}\t${stabilityAndHeatFluxMMS.name}\t${
            precipitationRateMMS.name
          }\t${dateLowerLimit.format(HEADER_ROW_DATE_FORMAT)}\t${dateUpperLimit.format(
            HEADER_ROW_DATE_FORMAT
          )}`,
        },
      };
    }
    default:
      return state;
  }
};
